<template>
  <div>
    <div style="margin: 10px 0">
      <el-select v-model="selectedLine" clearable placeholder="请选择车间">
        <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
      </el-select>
      <el-input style="width: 200px" placeholder="请输入设备名" suffix-icon="el-icon-search" v-model="equipmentName"></el-input>
      <el-select clearable multiple v-model="selectedType" placeholder="请选择类别">
        <el-option label="车间报修" value="车间报修"></el-option>
        <el-option label="维修自查" value="维修自查"></el-option>
        <el-option label="管理巡审" value="管理巡审"></el-option>
        <el-option label="获批技改" value="获批技改"></el-option>
        <!--                <el-option label="定期维修" value="定期维修"></el-option>-->
      </el-select>
      <el-select clearable multiple v-model="failureType" placeholder="请选择故障类型">
        <el-option label="故障" value="故障"></el-option>
        <el-option label="宕机" value="宕机"></el-option>
        <el-option label="预防性" value="预防性"></el-option>
        <el-option label="修旧利废" value="修旧利废"></el-option>
      </el-select>
      <el-select clearable multiple v-model="state" placeholder="请选择状态">
        <el-option label="等待中" value="等待中"></el-option>
        <el-option label="已接单" value="已接单"></el-option>
        <el-option label="维修中" value="维修中"></el-option>
        <el-option label="作业结束" value="作业结束"></el-option>
        <el-option label="工单关闭" value="工单关闭"></el-option>
      </el-select>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" v-if="this.user.role!=='ROLE_READ'">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference" v-if="this.user.role!=='ROLE_READ'">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
       <el-upload action="http://39.106.92.106:9090/repairReport/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5" v-if="user.role!=='ROLE_READ'" >导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column label="报修人" width="100">
        <template v-slot="scope">
          <span>{{ getUserName(scope.row.userId) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="车间" width="95">
        <template v-slot="scope">
          <span>{{ getLineName(scope.row.lineId)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="equipmentName" label="设备"></el-table-column>
      <el-table-column prop="type" label="报修类型"></el-table-column>
      <el-table-column prop="failureType" label="故障类型"></el-table-column>
      <el-table-column prop="state" label="状态"></el-table-column>
      <el-table-column prop="recordTime" label="报修时间"></el-table-column>
      <el-table-column prop="failureTime" label="故障时间"></el-table-column>
      <el-table-column prop="description" label="故障描述"></el-table-column>

      <el-table-column label="操作"  width="280" >
        <template slot-scope="scope">
          <el-button type="primary" plain slot="reference" @click="gotoDetail(scope.row.id)">查看 <i class="el-icon-remove-outline"></i></el-button>
          <el-button type="success" @click="handleEdit(scope.row)" v-if="user.role!=='ROLE_READ'" >编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference" v-if="user.role!=='ROLE_READ'" >删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="报修人">
          <el-select clearable filterable v-model="form.userId" placeholder="请选择报修人" style="width: 100%">
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报修类型">
          <el-select clearable v-model="form.type" placeholder="请选择类别" style="width: 100%">
            <el-option label="车间报修" value="车间报修"></el-option>
            <el-option label="维修自查" value="维修自查"></el-option>
            <el-option label="管理巡审" value="管理巡审"></el-option>
            <el-option label="获批技改" value="获批技改"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障类型">
          <el-select clearable v-model="form.failureType" placeholder="请选择类别" style="width: 100%">
            <el-option label="故障" value="故障"></el-option>
            <el-option label="宕机" value="宕机"></el-option>
            <el-option label="预防性" value="预防性"></el-option>
            <el-option label="修旧利废" value="修旧利废"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车间" prop="lineId" class="custom-form-item">
          <el-select clearable v-model="form.lineId" placeholder="请选择车间" style="width: 100%">
            <el-option v-for="item in lines" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备" prop="equipmentId" class="custom-form-item">
          <el-select filterable clearable v-model="form.equipmentId" placeholder="请选择设备" style="width: 100%">
            <el-option v-for="item in filteredEquipments" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
        <el-select clearable v-model="form.state" placeholder="请选择状态" style="width: 100%">
          <el-option label="维修中" value="维修中"></el-option>
          <el-option label="暂停中" value="暂停中"></el-option>
          <el-option label="作业结束" value="作业结束"></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="报修时间">
          <el-date-picker v-model="form.recordTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="故障时间">
          <el-date-picker v-model="form.failureTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="故障描述">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>
<!--        <el-form-item label="故障图">
          <div style="display: flex; flex-wrap: wrap; gap: 10px;">
            <el-image
                v-for="(file, index) in reportFiles"
                :key="index"
                :src="file.fileUrl"
                :preview-src-list="reportFiles.map(item => item.fileUrl)"
                style="width: 100px; height: 100px; margin-bottom: 10px;"
            >
            </el-image>
          </div>
          <el-upload
              class="avatar-uploader"
              action="http://39.106.92.106:9090/files/upload"
              :show-file-list="true"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeUpload"
              :multiple="true"
          >
            <el-button type="primary">上传照片</el-button>
            <span style="font-size: 12px">（可传多张）</span>
          </el-upload>
        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="dialogFormVisible2" width="30%" :close-on-click-modal="false">
      <div class="dialog-content">
        <el-card class="box-card" style="width: 100%;">
          <div slot="header" class="clearfix">
            <span>报修单</span>
          </div>
          <el-form label-width="140px" size="large">
            <el-form-item label="报修人:">
              <span>{{ getUserName(reports.userId) }}</span>
            </el-form-item>
            <el-form-item label="设备:">
              <span>{{ reports.equipmentName }}</span>
            </el-form-item>
            <el-form-item label="工单类型:">
              <span>{{ reports.type }}</span>
            </el-form-item>
            <el-form-item label="故障类型:">
              <span>{{ reports.failureType }}</span>
            </el-form-item>
            <el-form-item label="报修时间:">
              <span>{{ reports.recordTime }}</span>
            </el-form-item>
            <el-form-item label="故障时间:">
              <span>{{ reports.failureTime }}</span>
            </el-form-item>
            <el-form-item label="故障描述:">
              <span>{{ reports.description }}</span>
            </el-form-item>
            <el-form-item label="故障图">
              <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                <el-image
                    v-for="(file, index) in reportFiles"
                    :key="index"
                    :src="file.fileUrl"
                    :preview-src-list="reportFiles.map(item => item.fileUrl)"
                    style="width: 100px; height: 100px; margin-bottom: 10px;"
                >
                </el-image>
              </div>
            </el-form-item>

          </el-form>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "RepairReport",
  data() {
    return {
      tableData: [],
      users: [],
      equipments: [],
      reports: [],
      lines: [],
      reportFiles: [],
      partments: [],
      fileUrls: [],
      equipmentTypes: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      startDate : "",
      endDate : "",
      selectedPart: "",
      selectedLine: "",
      equipmentName: "",
      state: [],
      failureType: [],
      selectedType: [],
      form: {},
      dialogFormVisible: false,
      dialogFormVisible2: false,
      multipleSelection: [],
      baseURLdata: request.defaults.baseURL,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.loadState()
    this.load()
  },
  computed: {
    filteredEquipments() {
      // 根据选择的车间过滤设备
      let filteredByLine = null
      filteredByLine = this.equipments.filter(equipment => equipment.lineId === this.form.lineId);
      return filteredByLine;
    }
  },
  methods: {
    load() {
      this.request.get("/repairReport/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
          endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
          partId: this.selectedPart,
          lineId: this.selectedLine,
          selectedType: this.selectedType.length > 0 ? this.selectedType.join(',') : '',
          failureType: this.failureType.length > 0 ? this.failureType.join(',') : '',
          state: this.state.length > 0 ? this.state.join(',') : '',
          equipmentName: this.equipmentName,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/user").then(res => {
        this.users = res.data
      })
      // this.request.get("/equipment").then(res => {
      //   this.equipments = res.data
      // })
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/partment").then(res => {
        this.partments = res.data
      })
      this.request.get("/equipment").then(res => {
        this.equipments = res.data
      })
      this.saveState()
    },
    saveState() {
      const state = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
        endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
        partId: this.selectedPart,
        lineId: this.selectedLine,
        selectedType: this.selectedType.length > 0 ? this.selectedType.join(',') : '',
        failureType: this.failureType.length > 0 ? this.failureType.join(',') : '',
        state: this.state.length > 0 ? this.state.join(',') : '',
        equipmentName: this.equipmentName,
      };
      sessionStorage.setItem('RepairReportState', JSON.stringify(state));
    },
    loadState() {
      const savedState = sessionStorage.getItem('RepairReportState');
      if (savedState) {
        const { partId, lineId, selectedType, failureType, state, equipmentName, startDate, endDate, pageNum, pageSize } = JSON.parse(savedState);
        this.partId = partId || null;
        this.lineId = lineId || null;
        this.selectedType = Array.isArray(selectedType) ? selectedType : (selectedType ? selectedType.split(',') : []);
        this.failureType = Array.isArray(failureType) ? failureType : (failureType ? failureType.split(',') : []);
        this.state = Array.isArray(state) ? state : (state ? state.split(',') : []);
        this.equipmentName = equipmentName || '';
        this.startDate = startDate ? new Date(startDate) : null;
        this.endDate = endDate ? new Date(endDate) : null;
        this.pageNum = pageNum || 1;
        this.pageSize = pageSize || 10;
      }
    },
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');
      let requestData = {}
      if(this.fileUrls != null) {
        requestData = {
          reportId: this.form.id,
          fileUrls: this.fileUrls,
          formattedRecordTime: formattedRecordTime
        }
      }
      if(this.form.equipmentId){
        this.form.equipmentName = this.equipments.find(v => v.id === this.form.equipmentId).name
      }
      this.request.post("/repairReport", this.form).then(res => {
        if (res.code === '200') {
          this.request.post("/reportFile/changeFile", requestData)
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleAvatarSuccess(res) {
      if (!Array.isArray(this.fileUrls)) {
        this.fileUrls = [];
      }
      this.fileUrls.push(res.data);
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const width = img.width;
            const height = img.height;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            // 开始以 0.9 的质量压缩图片，然后递归调整
            this.compressImage(canvas, file.type, 0.5, resolve, reject);
          };
        };

        reader.onerror = (error) => {
          console.error("图片读取失败：", error);
          reject(error);
        };
      });
    },
    getUserName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (!user) return '';
      return user ? user.nickname : '';
    },
    getLineName(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    gotoDetail(reportId){
      this.request.get("/repairReport/"+reportId).then(res => {
        this.reports = res.data;
      });
      this.request.get("/reportFile/getByReportId/"+reportId).then(res => {
        this.reportFiles = res.data
      })
      this.dialogFormVisible2 = true
    },
    handleEdit(row) {
      if(this.user.role!=='ROLE_READ'){
        this.form = JSON.parse(JSON.stringify(row))
        this.dialogFormVisible = true
        this.$nextTick(() => {
          if(this.$refs.img) {
            this.$refs.img.clearFiles();
          }
          if(this.$refs.file) {
            this.$refs.file.clearFiles();
          }
        })
      }else{
        this.$message.warning("该账号没有权限")
      }

    },
    del(id) {
      if(this.user.role!=='ROLE_READ'){
        this.request.delete("/repairReport/" + id).then(res => {
          if (res.code === '200') {
            this.$message.success("删除成功")
            this.load()
          } else {
            this.$message.error("删除失败")
          }
        })
      }else{
        this.$message.warning("该账号没有权限")
      }

    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/repairReport/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.startDate = ""
      this.endDate = ""
      this.equipmentName = ""
      this.selectedLine = ""
      this.selectedType = []
      this.failureType = []
      this.state = []
      this.load()
      sessionStorage.removeItem('RepairReportState');
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    download(url) {
      window.open(url)
    },
    exp() {
      //window.open(this.baseURLdata+"/repairReport/export")
      // 获取筛选条件的值
      const params = new URLSearchParams();
      if (this.equipmentName) {
        params.append('equipmentName', this.equipmentName);
      }
      if (this.selectedLine) {
        params.append('selectedLine', this.selectedLine);
      }
      if (this.selectedType) {
        params.append('selectedType', this.selectedType);
      }
      if (this.failureType) {
        params.append('failureType', this.failureType);
      }
      if (this.state) {
        params.append('state', this.state);
      }
      if (this.startDate) {
        params.append('startDate', this.startDate ? `${this.startDate.getFullYear()}-${(this.startDate.getMonth() + 1).toString().padStart(2, '0')}-${this.startDate.getDate().toString().padStart(2, '0')}` : '');
      }
      if (this.endDate) {
        params.append('endDate', this.endDate ? `${this.endDate.getFullYear()}-${(this.endDate.getMonth() + 1).toString().padStart(2, '0')}-${this.endDate.getDate().toString().padStart(2, '0')}` : '');
      }

      // 打开新窗口进行导出
      window.open(this.baseURLdata + "/repairReport/export?" + params.toString());
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
